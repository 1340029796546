import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Expert Pods - Brand Assets">
            <Row>
                <Col>
                    <Link to="/conference">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <LinkCard to="/conference/expert-pods/standard-graphics" title="Standard Graphics" />
                <LinkCard to="/conference/expert-pods/templates" title="Templates" />
            </Row>
        </Layout>
    )
}

export default IndexPage
